import React from "react"
import { Link } from "gatsby"
import {
  active,
  paginationWrapper,
  paginationLabel,
  pagination,
} from "./paging.module.css"
import linkBuilder from "../../modules/linkBuilder"
import { FaCaretRight, FaCaretLeft } from "react-icons/fa"

const Paging = ({ pageContext, desk, deskCategory, tag }) => {
  const { currentPage, numPages, totalArticles, startArticle, endArticle } =
    pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1

  const pageLinks = () => {
    const links = []
    if (!isFirst) {
      if (desk)
        links.push(
          <Link
            key="page-prev"
            to={linkBuilder.desk(desk, prevPage)}
            rel="prev"
          >
            <FaCaretLeft />
          </Link>
        )
      if (deskCategory)
        links.push(
          <Link
            key="page-prev"
            to={linkBuilder.deskCategory(deskCategory, prevPage)}
            rel="prev"
          >
            <FaCaretLeft />
          </Link>
        )
      if (tag)
        links.push(
          <Link key="page-prev" to={linkBuilder.tag(tag, prevPage)} rel="prev">
            <FaCaretLeft />
          </Link>
        )
    }
    for (let page = 1; page <= numPages; page++) {
      let firstPageButton = 1
      let lastPageButton = 10

      if (numPages > 10) {
        firstPageButton = currentPage - 5
        lastPageButton = currentPage + 4
        if (firstPageButton < 1) {
          firstPageButton = 1
          lastPageButton = 10
        }
        if (lastPageButton > numPages) {
          lastPageButton = numPages
          firstPageButton = numPages - 10
        }
      }

      if (page >= firstPageButton && page <= lastPageButton) {
        if (desk)
          links.push(
            <Link
              activeClassName={active}
              key={`page-${page}`}
              to={linkBuilder.desk(desk, page)}
            >
              {page}
            </Link>
          )
        if (deskCategory)
          links.push(
            <Link
              activeClassName={active}
              key={`page-${page}`}
              to={linkBuilder.deskCategory(deskCategory, page)}
            >
              {page}
            </Link>
          )
        if (tag)
          links.push(
            <Link
              activeClassName={active}
              key={`page-${page}`}
              to={linkBuilder.tag(tag, page)}
            >
              {page}
            </Link>
          )
      }
    }
    if (!isLast) {
      if (desk)
        links.push(
          <Link
            key="page-next"
            to={linkBuilder.desk(desk, nextPage)}
            rel="next"
          >
            <FaCaretRight />
          </Link>
        )
      if (deskCategory)
        links.push(
          <Link
            key="page-next"
            to={linkBuilder.deskCategory(deskCategory, nextPage)}
            rel="next"
          >
            <FaCaretRight />
          </Link>
        )
      if (tag)
        links.push(
          <Link key="page-next" to={linkBuilder.tag(tag, nextPage)} rel="next">
            <FaCaretRight />
          </Link>
        )
    }
    return links
  }

  if (numPages <= 1) return <></>

  return (
    <div className={paginationWrapper}>
      <p className={paginationLabel}>
        Getoond {startArticle} tot en met {endArticle} van {totalArticles}{" "}
        totaal, er zijn meerdere pagina's
      </p>
      <div className={pagination}>{pageLinks()}</div>
    </div>
  )
}

export default Paging
