import React, { useState } from "react"
import { trackDisplay } from "../../services/tracking"
import { currentUser } from "../../services/auth";
import VizSensor from 'react-visibility-sensor';

const DisplayTracking = ({
  contentfulId,
  contentTitle,
  contentType,
  displayPosition,
  displayPageContentfulId,
  displayPageTitle,
  displayPagePath,
  formFields,
  children
}) => {

  const [visibleState, setVisibleState] = useState(false);

  return (
    <VizSensor
      active={!visibleState}
      partialVisibility
      onChange={async (isVisible) => {
        setVisibleState(isVisible);
        if (isVisible) {
          const authUser = await currentUser();
          trackDisplay(
            authUser.user.uid,
            contentfulId,
            contentTitle,
            contentType,
            {
              displayPosition,
              displayPageContentfulId,
              displayPageTitle,
              displayPagePath,
              formFields
            }
          );
        }
      }}>
      <>{children}</>
    </VizSensor>
  )
}

export default DisplayTracking
