/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SocialMeta({ title, description, image, url, appendBrand }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const siteTitle = site.siteMetadata.title;
  const socialDescription = description || site.siteMetadata.description
  if (appendBrand !== false) title = title ? `${title} - ${siteTitle}` : siteTitle;

  try {
    if (image && image.startsWith('//')) {
      image = `https:${image}`;
    }
  } catch (err) {
    console.error(err);
  }

  return (
    <Helmet>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={socialDescription} />
      {image &&
        <meta property="og:image" content={image} />
      }
      <meta property="og:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={socialDescription} />
      {image &&
        <meta name="twitter:image" content={image} />
      }
      {image &&
        <meta name="twitter:card" content="summary_large_image" />
      }

    </Helmet >
  )
}

export default SocialMeta
