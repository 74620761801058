// extracted by mini-css-extract-plugin
export var articles = "article-card-module--articles--f4309";
export var category = "article-card-module--category--2772b";
export var darkGradient = "article-card-module--dark-gradient--6a163";
export var darkStyle = "article-card-module--dark-style--1a82d";
export var deskArticlesGrid = "article-card-module--desk-articles-grid--41dbb";
export var deskContent = "article-card-module--desk-content--49795";
export var featured = "article-card-module--featured--3632f";
export var footer = "article-card-module--footer--5db60";
export var footerButton = "article-card-module--footer-button--a561c";
export var footerDivider = "article-card-module--footer-divider--4911f";
export var gatsbyImageWrapper = "article-card-module--gatsby-image-wrapper--da083";
export var inner = "article-card-module--inner--aea33";
export var outer = "article-card-module--outer--3a448";
export var readMoreText = "article-card-module--read-more-text--5635d";
export var wideStyle = "article-card-module--wide-style--b6f76";
export var wrapper = "article-card-module--wrapper--8c141";