import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import LinesEllipsis from "react-lines-ellipsis"
import {
  wrapper,
  outer,
  inner,
  footerDivider as footerDividerClass,
  footer,
  footerButton,
} from "./article-card.module.css"
import { FaClock, FaSyncAlt } from "react-icons/fa"
import { mostRecentDate, readTimeFromRichText } from "../../modules/helpers"

const ArticleCard = ({
  title,
  parentTitle,
  subtitle,
  image,
  link,
  description,
  publishDate,
  updateDate,
  readMoreButton,
  footerDivider,
  body,
}) => {
  const mostRecent = mostRecentDate(publishDate, updateDate)
  const readTime = readTimeFromRichText(body?.raw)
  return (
    <Link to={link} className={`${wrapper}`}>
      <article>
        <header className={`${outer} clearfix`}>
          <div className={`${inner}`}>
            {image && (
              <GatsbyImage image={image} alt={image.title ? image.title : ""} />
            )}
          </div>
        </header>
        {<h1>{title}</h1>}
        {subtitle && <h2>{subtitle}</h2>}
        <p>
          {description && (
            <LinesEllipsis
              text={description}
              maxLine="5"
              ellipsis="..."
              basedOn="words"
            />
          )}
        </p>
        <div className="gradient"></div>

        <footer
          className={footerDivider ? `${footerDividerClass} ${footer}` : footer}
        >
          {readMoreButton && (
            <button className={footerButton}>Lees artikel</button>
          )}

          {mostRecent && (
            <span className="updateDate">
              <FaSyncAlt /> {mostRecent}
            </span>
          )}
          {readTime && (
            <span className="readTime">
              <FaClock /> {readTime} minuten
            </span>
          )}
        </footer>
      </article>
    </Link>
  )
}

export default ArticleCard
