import firebase from "gatsby-plugin-firebase"

let currentPage = {};

export const setCurrentPage = (data) => {
    currentPage = data;
    return;
}

export const getCurrentPage = () => {
    return currentPage;
}

export const trackDisplay = async (
    uid,
    contentfulId,
    contentTitle,
    contentType,
    optional = {
        displayPosition: null,
        displayPageContentfulId: null,
        displayPageTitle: null,
        displayPagePath: null,
        formFields: null
    }) => {
    optional = optional || {};

    const event = {
        "contentfulId": contentfulId,
        "contentfulTitle": contentTitle,
        "contentType": contentType,
        "displayedAt": firebase.firestore.FieldValue.serverTimestamp(),
    }

    if (optional.displayPosition) event.displayPosition = optional.displayPosition;
    if (optional.displayPageContentfulId)
        event.displayPageContentfulId = optional.displayPageContentfulId;
    if (optional.displayPageTitle) event.displayPageTitle = optional.displayPageTitle;
    if (optional.displayPagePath) event.displayPagePath = optional.displayPagePath;

    try {
        if (optional.formFields) {
            event.requestedFormFields = optional.formFields.length;
            event.requestedFormFieldNames = optional.formFields;
        }
    } catch (err) { }

    try {
        await firebase.firestore()
            .collection("users")
            .doc(uid)
            .collection("displayTracking")
            .add(event)
    } catch (error) {
        console.log(error);
    }

}

export const eventTracking = async (eventType, options) => {
    const authUser = await firebase.auth().currentUser;
    if (!authUser) return;
    
    const event = {
        eventType,
        eventAt: firebase.firestore.FieldValue.serverTimestamp()
    }

    if (options?.contentfulId) event.contentfulId = options.contentfulId;
    if (options?.contentfulType) event.contentfulType = options.contentfulType;
    if (options?.contentfulTitle) event.contentfulTitle = options.contentfulTitle;
    if (options?.displayPageContentfulId) event.displayPageContentfulId = options.displayPageContentfulId;
    if (options?.displayPageTitle) event.displayPageTitle = options.displayPageTitle;
    if (options?.displayPagePath) event.displayPagePath = options.displayPagePath;
    if (options?.displayPosition) event.displayPosition = options.displayPosition;

    try {
        await firebase.firestore()
            .collection("users")
            .doc(authUser.uid)
            .collection("eventTracking")
            .add(event);
        if (options?.contentfulId) {
            const yyyy = new Date().getFullYear();
            const mm = new Date().getMonth() + 1;
            const dd = new Date().getDate();
            const monthly = `${yyyy}-${mm.toString().padStart(2, '0')}`;
            const daily = `${yyyy}-${mm.toString().padStart(2, '0')}-${dd.toString().padStart(2, '0')}`;

            const eventDoc = firebase.firestore()
            .collection("contentfulEntries")
            .doc(options.contentfulId)
            .collection("eventTracking")
            .doc(eventType);

            try {
                await eventDoc
                    .collection("monthly")
                    .doc(monthly)
                    .collection("users")
                    .doc(authUser.uid)
                    .set(event);
            } catch (error) {
                console.log("error in monthly users", error);
             }

            try {
                await eventDoc
                    .collection("monthly")
                    .doc(monthly)
                    .collection("total")
                    .add(event);
            } catch (error) {
                console.log("error in monthly total", error);
             }

            try {
                await eventDoc
                    .collection("daily")
                    .doc(daily)
                    .collection("users")
                    .doc(authUser.uid)
                    .set(event);
            } catch (error) { 
                console.log("error in daily users", error);
            }

            try {
                await eventDoc
                    .collection("daily")
                    .doc(daily)
                    .collection("total")
                    .add(event);
            } catch (error) {
                console.log("error in daily total", error);
             }

            try {
                await eventDoc
                    .collection("users")
                    .doc(authUser.uid)
                    .set(event);
            } catch (error) {   
                console.log("error in users", error);
             }

            try {
                await eventDoc
                    .collection("total")
                    .add(event);
            } catch (error) { 
                console.log("error in total", error);
            }
        }

    } catch (error) {
        console.log(error);
    }
}

export const trackExternalLink = async (uid, contentfulId, link) => {
    const page = getCurrentPage();
    try {
        await firebase.firestore()
            .collection("users")
            .doc(uid)
            .collection("externalLink")
            .doc(page.contentfulId)
            .set({
                "gaClientId": gaClientId(),
                "displayPageContentfulId": page.contentfulId,
                "displayPageTitle": page.title,
                "displayPagePath": page.path,
                "contentfulId": contentfulId,
                "link": link,
                "date": firebase.firestore.FieldValue.serverTimestamp()
            }, { merge: true })
    } catch (error) {
        console.log(error);
    }
}

export const contentScroll = async (uid, contentType, contentfulId, scrollPercentage) => {
    const event = {
        "lastScrolledAt": firebase.firestore.FieldValue.serverTimestamp(),
        "scroll": scrollPercentage
    }

    try {
        await firebase.firestore()
            .collection("users")
            .doc(uid)
            .collection(contentType)
            .doc(contentfulId)
            .set(event, { merge: true })
    } catch (error) {
        console.log(error);
    }

}

export const gaClientId = () => {
    try {
        return document.cookie.match('(^|;)\\s*_ga\\s*=\\s*([^;]+)')[2].split('.').slice(2, 4).toString().replace(',', '.')
    } catch (err) {
        return null;
    }
}