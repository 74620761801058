import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import get from "lodash/get"

import linkBuilder from "../../modules/linkBuilder"

/* Components */
import Layout from "../../components/layout"
import Seo from "../../components/seo/seo"
import SocialMeta from "../../components/social-meta/social-meta"
import Header from "../../components/header/header"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import Sponsor from "../../components/sponsor/sponsor"
import ArticleCard from "../../components/article-card/article-card"
import SideColumn from "../../components/side-column/side-column"
import Paging from "../../components/paging/paging"
import Footer from "../../components/footer/footer"

/* Styles */
import {
  MainAndSideWrapper,
  DeskContent,
  deskArticlesGrid,
  DeskAside,
} from "./desk-category.module.css"

class DeskCategoryTemplate extends React.Component {
  render() {
    const category = get(this.props, "data.contentfulDeskCategory")
    const categoryDescription = get(
      category,
      "description.childMarkdownRemark.rawMarkdownBody"
    )
    const categories = get(category, "desk.deskcategory")
    const articles = get(this.props, "data.allContentfulArticle")
    const desks = get(this.props, "pageContext.desks")
    const currentPage = get(this.props, "pageContext.currentPage", 0)
    const deskCategoryUrl = `https://www.mkbservicedesk.nl${linkBuilder.deskCategory(
      category,
      currentPage
    )}`

    return (
      <Layout location={this.props.location}>
        <Seo
          title={
            (category.pageTitle || category.name) +
            `${currentPage > 1 ? " (Pagina: " + currentPage + ")" : ""}`
          }
          description={
            category.metaDescription ||
            categoryDescription ||
            `In deze rubriek vertelt MKB Servicedesk je alles over ${
              category.name
            } ${
              category.desk?.name ? "binnen de " + category.desk.name : ""
            } en lees je het laatste nieuws! ${
              currentPage > 1 ? "(Pagina: " + currentPage + ")" : ""
            }`
          }
          canonicalUrl={category.canonicalUrl}
          noIndex={category.noIndex}
        />
        <SocialMeta
          title={category.socialTitle || category.name}
          description={category.socialDescription || categoryDescription}
          url={linkBuilder.deskCategory(category)}
          image={
            category.desk &&
            category.desk.image &&
            category.desk.image.gatsbyImageData &&
            category.desk.image.gatsbyImageData.images.fallback.src
          }
        />
        <Helmet>
          <link rel="canonical" href={deskCategoryUrl} />
        </Helmet>

        {category.desk && category.desk.image && (
          <Header
            desks={desks}
            data={{ ...category, body: null }}
            backgroundImage={category.desk.image.gatsbyImageData}
          />
        )}
        {(!category.desk || !category.desk.image) && (
          <Header desks={desks} data={{ ...category, body: null }} />
        )}

        <main className="main dossier">
          <div className="container">
            <Breadcrumb
              desk={category.desk}
              desks={desks}
              deskCategory={category}
              deskCategories={categories}
            />

            <div className={MainAndSideWrapper}>
              <section className={DeskContent}>
                {category.sponsor && <Sponsor sponsor={category.sponsor} />}

                <section className={deskArticlesGrid}>
                  {articles.nodes.map((article, index) => {
                    return (
                      <ArticleCard
                        key={`article-${index}`}
                        title={article.title}
                        subtitle={article.subtitle}
                        image={article.image?.gatsbyImageData}
                        // parentTitle={category.desk?.name}
                        description={
                          article.description?.childMarkdownRemark
                            ?.rawMarkdownBody
                        }
                        link={linkBuilder.article(article)}
                        publishDate={article.publishDate}
                        updateDate={article.updateDate}
                        readMoreEndOfText={true}
                        footerPublicationDate={true}
                        footerDivider={true}
                        body={article.body}
                      />
                    )
                  })}
                </section>

                {this.props.pageContext && (
                  <Paging
                    pageContext={this.props.pageContext}
                    deskCategory={category}
                  />
                )}
              </section>

              <div className={DeskAside}>
                <SideColumn categories={categories} />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </Layout>
    )
  }
}

export default DeskCategoryTemplate

export const pageQuery = graphql`
  query DeskCategoryBySlug($slug: String!, $skip: Int!, $limit: Int!) {
    allContentfulArticle(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: updateDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        subtitle
        slug
        slug2
        legacyId
        body {
          raw
        }
        publishDate(formatString: "DD MMMM YYYY", locale: "nl")
        publishDateInt: publishDate(formatString: "YYYY-MM-DD")
        updateDate(formatString: "DD MMMM YYYY", locale: "nl")
        updateDateInt: updateDate(formatString: "YYYY-MM-DD")
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        categories {
          name
          slug
          slug2
          desk {
            name
            slug
            slug2
            deskcategory {
              name
              slug
              slug2
              legacyId
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }

    contentfulDeskCategory(slug: { eq: $slug }) {
      name
      slug
      slug2
      legacyId
      pageTitle
      metaDescription
      canonicalUrl
      noIndex
      desk {
        name
        slug
        slug2
        legacyId
        pageTitle
        metaDescription
        socialTitle
        socialDescription
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        deskcategory {
          name
          slug
          slug2
          legacyId
          priority
          desk {
            slug2
          }
        }
      }
      sponsor {
        name
        url
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          title
          gatsbyImageData(layout: CONSTRAINED, width: 250, quality: 100)
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`
